class Facetedsearch{
    constructor(options) {
        this.options = options;

        this.fSearchProdListing = $('#facetedsearch__product-listing');
        this.searchParameters = this.fSearchProdListing.data('qsearch');


        if (this.fSearchProdListing.length ){
            this.bind();
        }
    }

    bind(){
        let _this = this;

        let searchParameters = this.fSearchProdListing.data('qsearch');

        if(searchParameters.indexOf('&page=') == -1){
            searchParameters = searchParameters + "&page=1";
        }

        _this.fetchProducts(searchParameters );

    }

    fetchProducts(searchParameters ){
        let _this = this;


        $.ajax({
            type: "GET",
            url: '/fsearch/products?'+ searchParameters + '&custom_data=productslist',
            dataType: 'json',
            success: function (data) {
                _this.fSearchProdListing.html(data.custom_data.html);

                try{
                    localStorage.removeItem("facetedProductList");
                    localStorage.setItem("facetedProductList", JSON.stringify(data.data.products));
                    // Add Price into Faceted Search Component
        var getdataFSearch = localStorage.getItem('facetedProductList');
        getdataFSearch = JSON.parse(getdataFSearch);
        for(let i=0;i < getdataFSearch.length; i++){
          var price = Number(getdataFSearch[i].price).toFixed(2);
            $('.fsearchDxp2 .products > div').eq(i).append('<p class="price text-5 mb-3 js-live-pricing" data-product-id="'+getdataFSearch[i].id+'"><span class="sale text-color-dark font-weight-semi-bold isFinalPrice">'+price+' AUD</span>');
        }
                  }
                  catch(e){
              
                  }

            },
            error: function (err) {
                console.log('error', err);
            },
            complete: function () {

                _this.renderProducts();


                _this.fSearchProdListing.find('.pagination .page').on('click', function (e){
                    e.preventDefault();
                    console.log($(this).find('.page-link').data('page-num'));
                    let pageNum = $(this).find('.page-link').data('page-num');
                    let searchParametersArray = searchParameters.split("&")
                    let updatedSearchParameters = searchParametersArray.map(function (x){
                        let y = x.split('=');
                        if(y[0] == 'page'){
                            y.pop();
                            y.push(pageNum);
                        }
                        return y.join("=")

                    })

                    //searchParameters = searchParameters + "&page=" + pageNum;
                    _this.fetchProducts(updatedSearchParameters.join("&") );
                })
            }
        });
    }

    renderProducts(){
       try {
        let _this = this;
        theme = theme || {};

        var instanceName = '__masonry';

        var initialized = false;

        var $el = _this.fSearchProdListing.find('.product-thumb-info-list');
        var opts = _this.fSearchProdListing.find('.product-thumb-info-list').data('plugin-options');

        new theme.PluginMasonry($el, opts);

        /*
        * Quick View Lightbox/Popup With Ajax
        */

        $('.quick-view').magnificPopup({
            type: 'inline',
            fixedContentPos: false,
            fixedBgPos: true,
            overflowY: 'auto',
            closeBtnInside: true,
            preloader: false,
            midClick: true,
            removalDelay: 300,
            mainClass: '',
            callbacks: {
                close: function() {
                    $('html').removeClass('lightbox-opened');
                    setTimeout(function(){
                        $('html').removeClass('lightbox-beforeclose');
                    }, 500);
                },
                beforeClose: function() {
                    $('html').addClass('lightbox-beforeclose');
                },
                open: function() {
                    $('html').addClass('lightbox-opened');

                    /*
                    Thumb Gallery
                    */
                    $('.thumb-gallery-wrapper').each(function(){
                        var $thumbGalleryDetail = $(this).find('.thumb-gallery-detail'),
                            $thumbGalleryThumbs = $(this).find('.thumb-gallery-thumbs'),
                            flag = false,
                            duration = 300;

                        $thumbGalleryDetail
                            .owlCarousel({
                                items: 1,
                                margin: 10,
                                nav: true,
                                dots: false,
                                loop: false,
                                autoHeight: true,
                                navText: [],
                                rtl: ( $('html').attr('dir') == 'rtl' ) ? true : false
                            })
                            .on('changed.owl.carousel', function(e) {
                                if (!flag) {
                                    flag = true;
                                    $thumbGalleryThumbs.trigger('to.owl.carousel', [e.item.index-1, duration, true]);

                                    $thumbGalleryThumbs.find('.owl-item').removeClass('selected');
                                    $thumbGalleryThumbs.find('.owl-item').eq( e.item.index ).addClass('selected');
                                    flag = false;
                                }
                            });


                        $thumbGalleryThumbs
                            .owlCarousel({
                                margin: 15,
                                items: $(this).data('thumbs-items') ? $(this).data('thumbs-items') : 4,
                                nav: false,
                                center: $(this).data('thumbs-center') ? true : false,
                                dots: false,
                                rtl: ( $('html').attr('dir') == 'rtl' ) ? true : false
                            })
                            .on('click', '.owl-item', function() {
                                $thumbGalleryDetail.trigger('to.owl.carousel', [$(this).index(), duration, true]);
                            })
                            .on('changed.owl.carousel', function(e) {
                                if (!flag) {
                                    flag = true;
                                    $thumbGalleryDetail.trigger('to.owl.carousel', [e.item.index, duration, true]);
                                    flag = false;
                                }
                            });

                        $thumbGalleryThumbs.find('.owl-item').eq(0).addClass('selected');


                    });

                    /*
                    * Image Gallery Zoom
                    */
                    if($.fn.elevateZoom) {
                        if( $('[data-zoom-image]').get(0) ) {
                            $('[data-zoom-image]').each(function(){
                                var $this = $(this);

                                $this.elevateZoom({
                                    responsive: true,
                                    zoomWindowFadeIn: 350,
                                    zoomWindowFadeOut: 200,
                                    borderSize: 0,
                                    zoomContainer: $this.parent(),
                                    zoomType: 'inner',
                                    cursor: 'grab'
                                });
                            });
                        }
                    }
                }
            }
        });

        INSPIRO.elements.modal();
       } catch (error) {
        
       }
    }

}

$(document).ready(function (){

    new Facetedsearch();
})
