class SpotPriceWatch{
    constructor(options) {
        this.options = options;

        this.filterButton = $('.btn-filter');
        this.filterContainer = $('.product-filter-container');
        if (this.filterButton && this.filterContainer){
            this.bindfilterButton();
        }

        this.loginAnchor = $('.header-nav-features-user a');
        if(this.loginAnchor) {
        	// this.updateLoginLink();
        }

        this.liveMetalPrice = $('.js-live-metal-price');
        if(this.liveMetalPrice.length > 0) {
        	this.addLiveMetalPricingLabel();
        }

        this.table = $('.js-spot-price-watch'),
		this.actions = this.table.find('.action-icons'),
		this.editBtn = this.actions.find('.isEdit'),
		this.saveBtn = this.actions.find('.isSave'),
		this.removeBtn = this.actions.find('.isRemove'),
		this.isPriceWatch = this.table.hasClass('js-is-price-watch'),
		this.addPriceAlertForm = $('form.addPriceAlert'),
		this.productPriceWatchBtn = $('a[href="#price-watch-form"]'),
		this.productPriceWatchForm = $('#price-watch-form'),
		this.productPriceAlertBtn = $('a[href="#price-alert-form"]'),
		this.productPriceAlertForm = $('#price-alert-form'),
		this.magnificPopup = $.magnificPopup.instance;
		// if(this.productPriceWatchBtn && this.productPriceAlertBtn) {
		// 	this.productDetailsButtonsBind();
		// }
		// this.bindEvents();
    }

    bindfilterButton(){
        let _this = this;
		_this.filterButton.on('click', function(e){
			e.preventDefault();
			if(_this.filterContainer.hasClass('d-block')) {
				_this.filterButton.removeClass('btn-secondary');
				_this.filterContainer.removeClass('d-block');
			} else {
				const sortHeight = $('.product-sort').outerHeight() - 3;
				_this.filterButton.addClass('btn-secondary');
				_this.filterContainer.css('top', sortHeight + 'px')
				_this.filterContainer.addClass('d-block');
			}
		});
    }
	//
    // updateLoginLink(){
    //     let _this = this;
    // 	$.ajax({
	// 		type: "GET",
	// 		url: '/index.php?action=pubuser&content_type=json&custom_data=logged_in_user',
	// 		success: function (data) {
	// 			if(data && data.custom_data) {
	// 				const loginLink = data.custom_data.isLoggedIn ? '/my-account/personal-details' : '/login/';
	// 				_this.loginAnchor.attr("href", loginLink)
	// 			}
	// 		}
	// 	});
    // }
	//
    // addLiveMetalPricingLabel(){
    //     let _this = this;
    //     $.ajax({
	// 		type: "GET",
	// 		url: 'https://api.nfusionsolutions.biz/api/v1/Metals/spot/summary?metals=gold,silver,platinum,palladium&currency=aud,usd&token=a1f2ffe5-6b4f-4cad-9947-0bdba9ea8af0&format=json',
	// 		success: function (data) {
	// 			_this.liveMetalPrice.each(function() {
	// 				const container = $(this),
	// 					metal = container.data('metal'),
	// 					currency = container.data('currency'),
	// 					metalFilterData = data.filter(metaJson => metaJson.requestedCurrency == currency && metaJson.requestedSymbol == metal.toLowerCase()),
	// 					metalData = metalFilterData[0].data;
	// 				if(metalData) {
	// 					const price = metalData.ask,
	// 						change = metalData.oneDayChange,
	// 						spanClass = change > 0 ? 'badge-success' : 'badge-danger',
	// 						iconClass = change > 0 ? 'fa-long-arrow-alt-up' : 'fa-long-arrow-alt-down';
	// 					container.html('<i class="fas '+iconClass+'"></i> $'+price.toFixed(2));
	// 					container.addClass(spanClass);
	// 				}
	// 			});
	// 		}
	// 	});
    // }
	//
    // productDetailsButtonsBind(){
    //     let _this = this;
	// 	const userCommentDataApi = _this.getUserCommentData();
	//
	// 	userCommentDataApi.done(function(data) {
	// 		const $data = _this.commentStringToJson(data),
	// 			priceWatchData = $data.productPriceWatch,
	// 			productID = _this.productPriceWatchBtn.data('product-code'),
	// 			priceAlertData = $data.spotPriceAlerts,
	// 			productMetal = _this.productPriceAlertBtn.data('metal');
	// 		if(priceWatchData.length > 0 && productID) {
	// 			const hasWatchData = priceWatchData.filter(watchData => watchData.productCode == productID).length > 0;
	// 			_this.productPriceWatchBtn.addClass(hasWatchData ? 'active' : '');
	// 		}
	// 		if(priceAlertData.length > 0 && productMetal) {
	// 			const hasAlertData = priceAlertData.filter(alertData => alertData.metal == productMetal).length > 0;
	// 			_this.productPriceAlertBtn.addClass(hasAlertData ? 'active' : '')
	// 		}
	// 	});
    // }
	//
    // bindEvents(){
    //     let _this = this;
    //     if(_this.editBtn) {
	// 		_this.editBtn.on('click', function(e){
	// 			e.preventDefault();
	// 			const $row = $(this).closest('tr, .items'),
	// 				$viewElements = $row.find('.isEditView, .isSave'),
	// 				$hideElements = $row.find('.isView, .isEdit');
	// 			_this.showHideElements($viewElements, $hideElements);
	// 		});
    //     }
    //     if(_this.saveBtn) {
	// 		_this.saveBtn.on('click', function(e){
	// 			e.preventDefault();
	// 			const $row = $(this).closest('tr, .items'),
	// 				updateIndex = $(this).data('update-index'),
	// 				$viewElements = $row.find('.isView, .isEdit'),
	// 				$updateView = $row.find('.isView'),
	// 				$hideElements = $row.find('.isEditView, .isSave'),
	// 				$formElements = $row.find(':input'),
	// 				fieldValue = parseFloat($row.find('input.price-input').val()).toFixed(2);
	// 			$updateView.text(_this.convertToCurrency(fieldValue));
	// 			_this.showHideElements($viewElements, $hideElements);
	// 			_this.updateData($formElements, updateIndex, 'update', _this.isPriceWatch);
	// 		});
    //     }
    //     if(_this.removeBtn) {
	// 		_this.removeBtn.on('click', function(e){
	// 			e.preventDefault();
	// 			const $row = $(this).closest('tr, .items'),
	// 				removeIndex = $(this).data('delete-index'),
	// 				$formElements = $row.find(':input, select');
	// 			_this.updateData($formElements, removeIndex, 'delete', _this.isPriceWatch);
	// 			$('.tooltip.show').hide();
	// 			$row.remove();
	// 		});
    //     }
    //     if(_this.addPriceAlertForm) {
	// 		_this.addPriceAlertForm.on('submit', function(e){
	// 			e.preventDefault();
	// 			_this.updateData($(this), '', 'add', false);
	// 			setTimeout(function(){ location.reload(); }, 3000);
	// 		});
    //     }
    //     if(_this.productPriceWatchForm) {
	// 		_this.productPriceWatchForm.on('submit', function(e){
	// 			e.preventDefault();
	// 			_this.magnificPopup.close();
	// 			_this.updateData($(this), '', 'add', true);
	// 			_this.productPriceWatchBtn.addClass('active');
	// 		});
    //     }
    //     if(_this.productPriceAlertForm) {
	// 		_this.productPriceAlertForm.on('submit', function(e){
	// 			e.preventDefault();
	// 			_this.magnificPopup.close();
	// 			_this.updateData($(this), '', 'add', false);
	// 			_this.productPriceAlertBtn.addClass('active');
	// 		});
    //     }
    // }
	//
	// updateData(formElements, index, action, isPriceWatch) {
    //     let _this = this;
	// 	const formData = _this.getFormData(formElements),
	// 		userCommentDataApi = _this.getUserCommentData();
	//
	// 	userCommentDataApi.done(function(data) {
	// 		const $data = _this.commentStringToJson(data),
	// 			changedData = isPriceWatch ? $data.productPriceWatch : $data.spotPriceAlerts;
	// 		if(action === 'update') {
	// 			changedData[index].price = formData.price;
	// 		} else if (action === 'delete') {
	// 			changedData.splice(index,1);
	// 		} else {
	// 			changedData.push(formData);
	// 		}
	// 		_this.saveAPICall($.extend({}, $data), _this.getSpotPriceModalMessageOption(action, isPriceWatch ? 'Product Price Watch' : 'Spot Price Alert'), action == 'delete');
	// 	});
	// }
	//
	// saveAPICall(comment, messageOptions, isDelete) {
    //     let _this = this;
	// 	const commentString = JSON.stringify(comment);
	// 	$.ajax({
	// 		type: "POST",
	// 		url: '/index.php?action=pubuser&form_name=updateComment&content_type=json&custom_data=ajax',
	// 		data: {
	// 			comment: commentString
	// 		},
	// 		success: function (data) {
	// 			if(!isDelete) {
	// 				_this.showMessage(messageOptions);
	// 			}
	// 		}
	// 	});
	// }
	//
	// getFormData($form){
	// 	var unindexed_array = $form.serializeArray();
	// 	var indexed_array = {};
	//
	// 	$.map(unindexed_array, function(n, i){
	// 		indexed_array[n['name']] = n['value'];
	// 	});
	//
	// 	return indexed_array;
	// }
	//
	// showHideElements(showElements, hideElements) {
	// 	showElements.removeClass('hidden');
	// 	hideElements.addClass('hidden');
	// }
	//
	// getUserCommentData() {
	// 	return $.ajax({
	// 		url: '/index.php?action=pubuser&content_type=json&custom_data=user-comment'
	// 	});
	// }
	//
	// commentStringToJson(data) {
	// 	const userCommentData = data.custom_data.comment;
	// 	if(userCommentData == null || userCommentData == '') {
	// 		const initialData = {"productPriceWatch":[],"spotPriceAlerts":[]};
	// 		return initialData;
	// 	}
	// 	return userCommentData;
	// }
	//
	// // determine modal message
	// getSpotPriceModalMessageOption(action, title) {
	// 	if (action === 'update') {
	// 		return {
	// 			title: 'Success!',
	// 			message: title+' updated.',
	// 			type: 'success'
	// 		};
	// 	} else if (action === 'delete') {
	// 		return {
	// 			title: 'Success!',
	// 			message: title+' removed.',
	// 			type: 'success'
	// 		};
	// 	} else {
	// 		return {
	// 			title: 'Success!',
	// 			message: title+' added.',
	// 			type: 'success'
	// 		};
	// 	}
	// }
	//
	//
    // showMessage(options) {
    //   $("#show-message-modal").on('show.bs.modal', function () {
    //     var modal = $(this);
    //     modal.find('.modal-title')
    //       .text(options.title)
    //       .removeClass('text-danger text-success')
    //       .addClass('text-' + options.type);
    //     modal.find('.modal-body').text(options.message);
    //     if(options.footerButtons) {
    //       modal.find('.modal-footer').html(options.footerButtons);
    //     }
    //   });
    //   $("#show-message-modal").modal('show');
    // }
	//
    // convertToCurrency(amount) {
    //   return '$'+amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // }
}

$(document).ready(function (){
    new SpotPriceWatch();
})