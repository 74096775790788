class Form{
    constructor(options) {
        this.options = options;

        this.newsletterSignup = $('#newsletter-signup');



        if (this.newsletterSignup.length ){
            this.bind();
        }
    }

    bind(){
        let _this = this;

        _this.newsletterSignup.on('submit', function (e){

            e.preventDefault();

            $.ajax({
                type: 'post',
                url: '/',
                data: _this.newsletterSignup.serialize(),
                success: function () {
                    _this.newsletterSignup.html(
                        '<div class="element-block static-element-block">' +
                        '<div class="element-text" title="">' +
                        '<p>Thank you for subscribing to our newsletter</p>' +
                        '</div>' +
                        '</div>'
                    )
                },
                error: function (err) {
                    console.log('error', err);
                }
            });

        });

    }



}

$(document).ready(function (){
    new Form();
})
