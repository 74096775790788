//import 'slick-carousel';

class ProductListingSlider{
    constructor(options) {
        this.options = options;

        this.productsListing = $('.product-listing .tab-content .tab-pane.active .products');

        if (this.productsListing.length){
            this.bind();
        }
    }

    bind(){
        let _this = this;

        _this.productsListing.each(function (){
            $(this).slick({
                dots: false,
                infinite: true,
                speed: 500,
                swipeToSlide: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                adaptiveHeight: true,
                centerMode: false,
                responsive: [
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,

                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            })
        })



    }
}

new ProductListingSlider();