//import 'owl.carousel';
//import Isotope from 'isotope-layout';

export default class PortfolioFilter {

    constructor(options) {
        this.options = options;

        this.portfolioLoadMoreFilter = $('.portfolioLoadMoreFilter');
        this.tabs = $('.portfolioLoadMoreFilter .nav .nav-item');

        if (this.portfolioLoadMoreFilter.length){
            this.bind();
        }
    }

    bind() {
        let _this = this;




        $(document).ready(function (){
            _this.portfolioLoadMoreFilter.each(function (){
                let $this = $(this);
                var tabs = $(this).find('.nav .nav-item');

                tabs.eq(0).addClass('active');
                tabs.eq(0).find('.nav-link').addClass('active');
                var initialContent = "";
                if(tabs.eq(0).hasClass('show-all')){
                    $this.find('.nav-item .hidden').each(function (){
                        initialContent = initialContent + $(this).html();
                    })
                }
                else {
                    initialContent = tabs.eq(0).find('.hidden').html();
                }


                $(this).find('.portfolioLoadMoreWrapper').html(initialContent);
                _this.initializeSlider();



                tabs.on('click', function (e) {
                    e.preventDefault();
                    tabs.removeClass('active');
                    tabs.find('.nav-link').removeClass('active');
                    $(this).addClass('active');
                    $(this).find('.nav-link').addClass('active');
                    var content = ""
                    if($(this).hasClass('show-all')){
                        $this.find('.nav-item .hidden').each(function (){
                            content = content + $(this).html();
                        })
                    }
                    else{
                        content = $(this).find('.hidden').html();
                    }

                    $this.find('.portfolioLoadMoreWrapper').html(content);
                    _this.initializeSlider();


                })
            })

        })



    };

    initializeSlider(){
        $('.portfolioLoadMoreFilter .owl-carousel').owlCarousel({
            loop: true,
            items: 1,
            lazyLoad: true,
            margin:20,
            animateOut: 'fadeOut',
            autoplay: true,
            autoplayTimeout: 3000,
            autoHeight: true,
            singleItem: true,
            responsive:{
                0: {
                    items: 1,
                },
                500: {
                    items: 1,
                },
                768: {
                    items: 1,
                }
            }
        });
    }

};

new PortfolioFilter();