class HeaderNotifications{
    constructor(options) {
        this.options = options;

        this.headerNotification = $('.header-nav-features .header-nav-features-notifications');
        this.headerNotificationCloseBtn = $('#headerTopNotificationDropdown .btn-close');
        this.headerNotificationContent = $('.header-nav-features .header-nav-features-notifications .notification_content');
        this.notificationURL = '/index.php?action=public&form_name=notifications';
        this.notificationReadClass = '.list-notifications .js-notification-link a';
        this.notificationRemoveClass = '.list-notifications a.isRemove';
        this.notificationIconContainer = '.header-nav-features-notifications a.header-nav-features-toggle';

        if (this.headerNotification.length){
            this.bind();
            this.updateNotificationHeader();
        }
    }

    bind(){
        let _this = this;

        _this.headerNotificationCloseBtn.on('click', function (e){
            e.preventDefault();
            $(this).closest('.header-nav-features-dropdown').removeClass('show');
        });

        $(document).on('click', _this.notificationReadClass, function (e){
            e.preventDefault();
            const notificationID = $(this).closest('li').data('notification-id');
            $.ajax({
                type: 'POST',
                context: this,
                url: '/index.php?action=pubuser&form_name=readNotifications&content_type=json&custom_data=ajax',
                data: {id:notificationID},
                complete: function(data){
                    location.href = this.href;
                }
            });
        });

        $(document).on('click', _this.notificationRemoveClass, function (e){
            e.preventDefault();
            const notificationItem = $(this).closest('li'),
                notificationID = notificationItem.data('notification-id');
            $.ajax({
                type: 'POST',
                context: this,
                url: '/index.php?action=pubuser&form_name=deleteNotification&content_type=json&custom_data=ajax',
                data: {id:notificationID},
                complete: function(data){
                    notificationItem.remove();
                    const $qtyElement = $('.notifications-qty'),
                        currNotificationsNo = $qtyElement ? $qtyElement.text() : 0;
                    if(currNotificationsNo > 0) {
                        const notificationsNo = currNotificationsNo - 1;
                        $qtyElement.text(notificationsNo);
                    }
                }
            });
        });
    }

    updateNotificationHeader() {
        let _this = this;
        
        $.ajax({
            dataType: "html",
            url: _this.notificationURL,
            type: 'get',
            success: function(data) {
                if(data) {
                    _this.headerNotificationContent.html(data);
                    const splitLength = data.split('data-notification-id').length,
                        notificationsNo = splitLength - 1;
                    if(notificationsNo > 0) {
                        $(_this.notificationIconContainer).append('<span class="notifications-info"><span class="notifications-qty">'+notificationsNo+'</span></span>');
                    }
                }
            }
        });
    }

}

$(document).ready(function (){
    new HeaderNotifications();
})
