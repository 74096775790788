import Tabs from './tabs';
class Facetedsearchbyid{
    constructor(options) {
        this.options = options;

        this.products = $('.product-listing .tabs .nav-tabs .products ');

        if (this.products.length){
            this.bind();
        }
    }

    bind(){
        let _this = this;

        _this.products.each(function (){
            let productIds = $(this).data('product-code');
            let $products = $(this);

            _this.fetchProducts(productIds, $products)

        })

    }

    fetchProducts(productIds, $products){
        let _this = this;

        $.ajax({
            type: "GET",
            url: '/fsearch/products?code='+ productIds + '&custom_data=featuredproducts',
            dataType: 'json',
            success: function (data) {
                $products.html(data.custom_data.html);
            },
            error: function (err) {
                console.log('error', err);
            },
            complete: function () {
                new Tabs();
            }
        });
    }


}

new Facetedsearchbyid();