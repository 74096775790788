
export default class Tabs {

    constructor(options) {
        this.options = options;

        this.customTabs = $('.tabs-custom');
        this.tabs = $('.tabs-custom .nav-custom-tabs .nav-item');

        if (this.customTabs.length){
            this.bind();
        }
    }

    bind() {
        let _this = this;

        const sliderSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            swipeToSlide: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            adaptiveHeight: true,
            centerMode: false,
            centerPadding: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,

                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };


        $(document).ready(function (){
            _this.customTabs.each(function (){

                var tabs = $(this).find('.nav-custom-tabs .nav-item');

                tabs.eq(0).find('.nav-link').addClass('active');
                var initialContent = tabs.eq(0).find('.hidden').html();

                $(this).find('.tab-content .tab-pane').html(initialContent);
                var productsList = $(this).find('.tab-content .tab-pane.active .products');

                if (productsList.length){
                    productsList.slick(sliderSettings);
                }

                tabs.on('click', function (e) {
                    tabs.find('.nav-link').removeClass('active');
                    $(this).find('.nav-link').addClass('active');
                    var content = $(this).find('.hidden').html();
                    $(this).parent('.nav-custom-tabs').next('.tab-content').find('.tab-pane').html(content);
                    productsList = $(this).parent('.nav-custom-tabs').next('.tab-content').find('.tab-pane.active .products')

                    if (productsList.length){
                        productsList.slick(sliderSettings);
                    }
                })
            })

        })
        

 
    };



};

new Tabs(); 