class BuyPriceCalculator{
    constructor(options) {
        this.options = options;
        this.buyPriceCalculator = $('#buy-price-calculator');
        this.buyPriceAmount = $('#buy-price-amount');
        this.buyPriceUnits = $('#buy-price-units');
        this.buyPriceMetal = $('#buy-price-metal');
        this.buyPrice = $('#buy-price');

        if (this.buyPriceCalculator.length ){
            this.bind();
        }
    }

    bind(){
        let _this = this;

        _this.updateBuyPrice();
        _this.buyPriceCalculator.on('change', 'select', function (){
            _this.updateBuyPrice();
        })
        _this.buyPriceAmount.on('input', function (){
            _this.updateBuyPrice();
        })
    }

    updateBuyPrice(){
        let _this = this;

        let amount = _this.buyPriceAmount.val();
        let unit = _this.buyPriceUnits.val();

        let currentMetalPerUnitPrice = _this.buyPriceMetal.find(':selected').data(unit);
        let priceAdjustmentToz = _this.buyPriceMetal.find(':selected').data('buffer');
        let metalPrice = currentMetalPerUnitPrice;
        if(unit === 'toz'){
            metalPrice = currentMetalPerUnitPrice - priceAdjustmentToz;
        }
        else if(unit == 'g'){
            metalPrice = currentMetalPerUnitPrice - (priceAdjustmentToz / 31.1035);
        }
        else if(unit == 'kg'){
            metalPrice = currentMetalPerUnitPrice - (priceAdjustmentToz / 0.0311035);
        }


        let price = metalPrice * amount;
        _this.buyPrice.text('$' + Number(parseFloat(price).toFixed(2)).toLocaleString('en', {
            minimumFractionDigits: 2
        }));

    }


}

$(document).ready(function (){
    new BuyPriceCalculator();
})
