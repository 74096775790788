
class CartLogin{
    constructor(options) {
        this.options = options;

        this.cartLogin = $('#cart-continue');
        this.cartLoginForm = $('.cartlogin__form');
        this.cartRecaptcha = $('.cartlogin__form .form__recaptcha');

        if (this.cartRecaptcha.length){
            this.bind();
        }
    }

    bind(){
        let _this = this;

        _this.cartLogin.on('click', function (e){
            e.preventDefault();
            _this.validateForm()

        })

    }

    validateForm(){
        let _this = this;
        if(grecaptcha.getResponse().length == 0){
            _this.cartLoginForm.find('.error').html('<p>ReCaptcha validation failed.</p>').fadeIn()
        }
        else{
            _this.cartLoginForm.submit();
        }
    }
}

new CartLogin();