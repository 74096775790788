$(document).ready(function(){
    
if($('.mobile-menu-container').length > 0){
 mobileMenuDxp();
}

if($(window).width() > 991){
    desktopMenuIcon();
}


})

function mobileMenuDxp() {



$('.mobile-menu li').each(function(){

    if($(this).children('ul').length>0){
        $(this).children('a').addClass('mmenu-link');
        $(this).children('a').append('<span class="mmenu-btn"></span>');
    }

})

    // Mobile Menu Toggle
    $('.mmenu-btn, .mmenu-link').on('click', function(e) {

        var $parent = $(this).closest('li')
          , $targetUl = $parent.find('ul').eq(0);

        if (!$parent.hasClass('open')) {
            $targetUl.slideDown(300, function() {
                $parent.addClass('open');
            });

	        e.stopPropagation();
	        e.preventDefault();
        } else {
	        if ($(this).hasClass('mmenu-btn')) {
		        $targetUl.slideUp(300, function() {
			        $parent.removeClass('open');
		        });
		        e.stopPropagation();
		        e.preventDefault();
	        }
        }
	});
};


function desktopMenuIcon(){

if($('header').hasClass('headerLayoutOne')){
   
    $('.main-nav.header-right li').each(function(){

        if($(this).children('ul').length > 0){
            $(this).children('a').addClass('sf-with-ul menuDIcon');
        }
    
    })

}



}